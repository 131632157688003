
type Plan = {
  id: string;
  name: string;
  speed: number;
  price: number;
  uploadSpeed: number;
  technology: "radio" | "fibra";
  features: string[];
};

type Location = {
  id: string;
  name: string;
  state: string;
  plans: Plan[];
};

const locations: Location[] = [
  {
    id: "farias-brito",
    name: "Farias Brito",
    state: "CE",
    plans: [
      {
        id: "farias-brito-radio-5",
        name: "5 Mega",
        speed: 5,
        uploadSpeed: 2.5,
        price: 49.99,
        technology: "radio",
        features: [
          "5 Mbps Download",
          "2.5 Mbps Upload",
          "IP Válido"
        ]
      },
      {
        id: "farias-brito-fibra-50-cliente",
        name: "50 Mega",
        speed: 50,
        uploadSpeed: 25,
        price: 49.99,
        technology: "fibra",
        features: [
          "50 Mbps Download",
          "25 Mbps Upload",
          "IP Válido",
          "Roteador do cliente"
        ]
      },
      {
        id: "farias-brito-fibra-50-comodato",
        name: "50 Mega",
        speed: 50,
        uploadSpeed: 25,
        price: 59.99,
        technology: "fibra",
        features: [
          "50 Mbps Download",
          "25 Mbps Upload",
          "IP Válido",
          "Comodato"
        ]
      },
      {
        id: "farias-brito-radio-10",
        name: "10 Mega",
        speed: 10,
        uploadSpeed: 5,
        price: 69.99,
        technology: "radio",
        features: [
          "10 Mbps Download",
          "5 Mbps Upload",
          "IP Válido"
        ]
      },
      {
        id: "farias-brito-fibra-300",
        name: "300 Mega",
        speed: 300,
        uploadSpeed: 150,
        price: 69.99,
        technology: "fibra",
        features: [
          "300 Mbps Download",
          "150 Mbps Upload",
          "IP Válido"
        ]
      },
      {
        id: "farias-brito-fibra-600",
        name: "600 Mega",
        speed: 600,
        uploadSpeed: 300,
        price: 79.99,
        technology: "fibra",
        features: [
          "600 Mbps Download",
          "300 Mbps Upload",
          "IP Válido"
        ]
      }
    ]
  },
  {
    id: "altaneira",
    name: "Altaneira",
    state: "CE",
    plans: [
      {
        id: "altaneira-radio-10",
        name: "10 Mega",
        speed: 10,
        uploadSpeed: 5,
        price: 49.99,
        technology: "radio",
        features: [
          "10 Mbps Download",
          "5 Mbps Upload",
          "IP Válido"
        ]
      },
      {
        id: "altaneira-radio-20",
        name: "20 Mega",
        speed: 20,
        uploadSpeed: 10,
        price: 59.99,
        technology: "radio",
        features: [
          "20 Mbps Download",
          "10 Mbps Upload",
          "IP Válido"
        ]
      }
    ]
  }
];

export const getDefaultLocation = () => locations[0];
export const getLocationById = (id: string) => locations.find(loc => loc.id === id) || locations[0];
export const getAllLocations = () => locations;

export type { Location, Plan };
