
import { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import type { Location } from '@/lib/locations';

type CityCoordinates = {
  center: [number, number];
  name: string;
};

const CoverageMap = () => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const cities: Record<string, CityCoordinates> = {
    'farias-brito': {
      center: [-39.5651, -6.9227] as [number, number],
      name: 'Farias Brito'
    },
    'altaneira': {
      center: [-39.74083, -7.00194] as [number, number],
      name: 'Altaneira'
    }
  };

  useEffect(() => {
    if (!mapContainer.current) return;

    // Inicializar mapa
    mapboxgl.accessToken = 'pk.eyJ1IjoidGFjaWFub3BpbmhlaXJvIiwiYSI6ImNtNmRrejE3dzBpeDgybG9rMmhlZm91azIifQ.GRduaN90Xyypdz2zAljjkw';
    
    // Calcular o centro entre as duas cidades
    const bounds = new mapboxgl.LngLatBounds();
    Object.values(cities).forEach(city => {
      bounds.extend(city.center);
    });
    
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      bounds: bounds,
      fitBoundsOptions: { 
        padding: 50,
        maxZoom: 11  // Diminuí o zoom máximo de 13 para 11
      }
    });

    // Adicionar controles de navegação
    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

    // Adicionar marcadores para cada cidade
    Object.entries(cities).forEach(([_, city]) => {
      // Criar elemento personalizado para o marcador
      const el = document.createElement('div');
      el.className = 'w-6 h-6 rounded-full bg-primary border-2 border-white shadow-lg';
      
      // Adicionar marcador ao mapa
      new mapboxgl.Marker(el)
        .setLngLat(city.center)
        .setPopup(new mapboxgl.Popup({ offset: 25 })
          .setHTML(`<h3 class="font-bold">${city.name}</h3>`))
        .addTo(map.current!);
    });

    // Cleanup
    return () => {
      map.current?.remove();
    };
  }, []);

  return (
    <div className="relative w-full h-[400px] rounded-lg overflow-hidden">
      <div ref={mapContainer} className="absolute inset-0" />
    </div>
  );
};

export default CoverageMap;
