
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import CoverageMap from "@/components/CoverageMap";
import { getAllLocations } from "@/lib/locations";

const Cobertura = () => {
  const locations = getAllLocations();

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Navbar />
      <main className="container mx-auto px-4 pt-24 pb-12 flex-grow">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl font-bold text-secondary mb-6">Área de Cobertura</h1>
          <p className="text-lg text-gray-600 mb-8">
            Confira as cidades onde nossos serviços estão disponíveis
          </p>
          
          <div className="mb-8">
            <CoverageMap />
          </div>

          <div className="bg-primary-light/10 p-6 rounded-lg">
            <h2 className="text-2xl font-bold text-primary mb-4">
              Serviços disponíveis nas seguintes cidades:
            </h2>
            <div className="space-y-2">
              {locations.map((location) => (
                <p key={location.id} className="text-gray-600">
                  {location.name} - {location.state}
                </p>
              ))}
            </div>
            <p className="mt-4 text-gray-600">
              Entre em contato conosco para verificar a disponibilidade específica para seu endereço.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Cobertura;
