import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

const Privacidade = () => {
  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Navbar />
      <main className="container mx-auto px-4 pt-24 pb-12 flex-grow">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-secondary mb-6">Política de Privacidade</h1>
          <div className="prose max-w-none">
            <p className="text-lg text-gray-600 mb-6">
              Última atualização: 22 de fevereiro de 2025
            </p>

            <p className="text-lg text-gray-600 mb-6">
              A Maxcon Internet valoriza a privacidade e segurança dos dados de seus clientes. Esta Política de Privacidade explica como coletamos, utilizamos e protegemos suas informações pessoais, em conformidade com a Lei Geral de Proteção de Dados (LGPD).
            </p>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-primary mb-4">1. Coleta de Dados</h2>
              <p className="text-gray-600">
                A Maxcon Internet coleta apenas os dados estritamente necessários para a prestação de serviços, incluindo:
              </p>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Dados cadastrais (nome, CPF/CNPJ, endereço, e-mail, telefone);</li>
                <li>Dados de pagamento para faturamento e cobrança;</li>
                <li>Informações técnicas para suporte e melhoria dos serviços (endereço IP, histórico de conexão, dispositivo utilizado).</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-primary mb-4">2. Uso das Informações</h2>
              <p className="text-gray-600 mb-4">
                Os dados coletados são utilizados exclusivamente para os seguintes fins:
              </p>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Prestância e execução dos serviços contratados;</li>
                <li>Comunicação com o cliente sobre produtos, planos e suporte;</li>
                <li>Melhorias na qualidade dos serviços e experiência do usuário;</li>
                <li>Atendimento a obrigações legais e regulatórias.</li>
              </ul>
              <p className="text-gray-600">
                A Maxcon Internet não compartilha seus dados com terceiros, exceto quando necessário para cumprimento de normas legais ou mediante autorização expressa do cliente.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-primary mb-4">3. Proteção de Dados</h2>
              <p className="text-gray-600">
                Os dados dos clientes são protegidos por medidas de segurança adequadas, incluindo:
              </p>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Criptografia de dados sensíveis;</li>
                <li>Controles de acesso restrito;</li>
                <li>Monitoramento contínuo para prevenção de ameaças;</li>
                <li>Conformidade com os padrões da LGPD.</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-primary mb-4">4. Uso de Cookies</h2>
              <p className="text-gray-600">
                Nosso site utiliza cookies para aprimorar a experiência do usuário. Os cookies são pequenos arquivos armazenados em seu dispositivo que auxiliam na personalização de conteúdo e análise de interação com a plataforma. Você pode gerenciar as permissões de cookies por meio das configurações do seu navegador.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-primary mb-4">5. Direitos do Titular dos Dados</h2>
              <p className="text-gray-600">
                Conforme previsto na LGPD, você tem direito a:
              </p>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Acessar seus dados pessoais e solicitar correções;</li>
                <li>Solicitar a exclusão ou anonimização dos dados, salvo em casos de exigência legal;</li>
                <li>Revogar consentimentos fornecidos anteriormente;</li>
                <li>Solicitar esclarecimentos sobre o tratamento de suas informações.</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-primary mb-4">6. Contato</h2>
              <p className="text-gray-600">
                Caso tenha dúvidas sobre esta Política de Privacidade ou deseje exercer seus direitos, entre em contato conosco pelo site www.maxcon.com.br ou por nossos canais de atendimento.
              </p>
              <p className="text-gray-600">
                Maxcon Internet - Seu direito à privacidade respeitado!
              </p>
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Privacidade;
