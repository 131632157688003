
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

const Sobre = () => {
  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Navbar />
      <main className="flex-grow container mx-auto px-4 pt-24 pb-12">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-secondary mb-6">Sobre a Maxcon Internet</h1>
          <div className="prose max-w-none space-y-6">
            <p className="text-lg text-gray-600">
              Desde 2008, a Maxcon Internet tem sido referência em conectividade, inovação e compromisso com seus clientes. Fundada em Farias Brito - CE, durante o período do carnaval, a empresa nasceu da necessidade de oferecer um serviço de internet de qualidade, independente das limitações das operadoras telefônicas da época, que condicionavam a conexão à assinatura de uma linha fixa.
            </p>
            <p className="text-lg text-gray-600">
              Nosso primeiro plano ofertado foi de 128kbps via rádio, trazendo uma alternativa acessível e confiável para os moradores da cidade. Ainda em 2008, expandimos nossos serviços para Altaneira - CE, consolidando nossa presença na região e reforçando nosso compromisso com a democratização do acesso à internet.
            </p>
            <p className="text-lg text-gray-600">
              Inicialmente, a empresa operava seus serviços homologados junto à rede Globalinfo, garantindo conformidade e segurança na oferta de internet. Em 2015, obtivemos nossa própria autorização junto à ANATEL (Ato nº 4.876/2015), permitindo total autonomia na homologação e gestão de nossos serviços, saindo da rede Globalinfo e consolidando a Maxcon Internet como uma provedora independente e inovadora.
            </p>            
            <p className="text-lg text-gray-600">
              Ano após ano, investimos em tecnologia e infraestrutura para aprimorar a experiência de nossos clientes. Em 2017, demos um passo significativo ao iniciar a oferta de planos em fibra óptica, garantindo maior velocidade, estabilidade e qualidade de conexão. Hoje, continuamos expandindo nossas operações, sempre com o objetivo de levar o melhor da tecnologia para cada vez mais pessoas e empresas.
            </p>

            <h2 className="text-2xl font-bold text-primary mb-4">Missão, Visão e Valores</h2>
            <h3 className="font-semibold text-gray-900 mb-4">Missão</h3>
            <p className="text-lg text-gray-600">
              Proporcionar acesso à internet de alta qualidade, promovendo a inclusão digital e impulsionando o desenvolvimento social e econômico das comunidades onde atuamos, por meio de tecnologia de ponta e atendimento excepcional.
            </p>            
            <h3 className="font-semibold text-gray-900 mb-4">Visão</h3>
            <p className="text-lg text-gray-600">
              Ser reconhecida como a principal referência em soluções de internet no Brasil, destacando-se pela inovação, confiabilidade e compromisso com a excelência.
            </p>            
            <h3 className="font-semibold text-gray-900 mb-4">Valores</h3>
            <ul className="space-y-2 text-gray-600">
              <li><strong>Compromisso com o Cliente: </strong>Colocamos nossos clientes no centro de tudo o que fazemos, garantindo transparência e um atendimento de excelência;</li>
              <li><strong>Inovação Contínua: </strong>Buscamos constantemente novas tecnologias para aprimorar nossos serviços e oferecer as melhores soluções em conectividade;</li>
              <li><strong>Integridade e Ética: </strong>Agimos com responsabilidade, respeito e honestidade em todas as nossas relações;</li>
              <li><strong>Desenvolvimento Sustentável: </strong>Investimos no crescimento responsável da empresa, contribuindo para o desenvolvimento das comunidades onde atuamos.</li>
              <li><strong>Excelência Operacional: </strong>Trabalhamos para garantir máxima eficiência e qualidade em todos os aspectos dos nossos serviços.</li>
            </ul>

            <h2 className="text-2xl font-bold text-primary mb-4">Dados da Empresa</h2>
            <ul className="space-y-2 text-gray-600">
              <li><strong>Razão Social:</strong> MAXCON ASSESSORIA, ORIENTACAO E ASSISTENCIA OPERACIONAL EM INFORMATICA LTDA</li>
              <li><strong>CNPJ:</strong> 08.212.384/0001-18</li>
              <li><strong>Sistema Autônomo: </strong> <a href="https://registro.br/tecnologia/ferramentas/whois?search=267361" target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:underline">ASN 267361</a></li>
              <li><strong>Desde:</strong> 2008</li>
              <li><strong>Autorização Anatel:</strong> <a href="/lovable-uploads/Ato4.876-2015.pdf" target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:underline">Ato nº 4.876/2015</a></li>
            </ul>
            <p className="text-lg text-gray-600">
              A Maxcon Internet segue firme em sua trajetória de crescimento e inovação, garantindo que seus clientes tenham acesso ao que há de mais moderno em conectividade. Nosso compromisso é continuar evoluindo e proporcionando um serviço de excelência para todas as pessoas e empresas que confiam em nossa marca.
            </p>  
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Sobre;
