import { Link } from "react-router-dom";
import { Github, Linkedin } from "lucide-react";
import { useEffect, useRef } from "react";

const Footer = () => {
  const footerRef = useRef<HTMLElement>(null);

  // When footer mounts, hide the placeholder
  useEffect(() => {
    const placeholder = document.getElementById('footerPlaceholder');
    if (placeholder) {
      placeholder.style.display = 'none';
    }
  }, []);

  return (
    <footer 
      ref={footerRef} 
      className="bg-gray-50 border-t border-gray-200 w-full" 
      style={{ 
        minHeight: "300px", 
        height: "auto", 
        contain: "layout size style",
        contentVisibility: "auto"
      }}
    >
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h2 className="font-semibold text-gray-900 mb-4 text-base">Ferramentas</h2>
            <ul className="space-y-2">
              <li>
                <a 
                  href="https://velocidade.maxcon.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-primary"
                >
                  Teste de Velocidade
                </a>
              </li>
              <li>
                <a 
                  href="http://provisionaronu.maxcon.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-primary"
                >
                  Provisionar ONU
                </a>
              </li>
            </ul>
          </div>
          
          <div>
            <h2 className="font-semibold text-gray-900 mb-4 text-base">Links Úteis</h2>
            <ul className="space-y-2">
              <li>
                <a 
                  href="https://sgp.maxcon.com.br/accounts/central/login"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-primary"
                >
                  Central do Assinante
                </a>
              </li>
              <li>
                <a 
                  href="http://dicas.maxcon.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-primary"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>
          
          <div>
            <h2 className="font-semibold text-gray-900 mb-4 text-base">Legal</h2>
            <ul className="space-y-2">
              <li>
                <Link to="/termos" className="text-gray-600 hover:text-primary">
                  Termos e Condições
                </Link>
              </li>
              <li>
                <Link to="/privacidade" className="text-gray-600 hover:text-primary">
                  Política de Privacidade
                </Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h2 className="font-semibold text-gray-900 mb-4 text-base">Mapa do site</h2>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-gray-600 hover:text-primary">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/sobre" className="text-gray-600 hover:text-primary">
                  Sobre
                </Link>
              </li>
              <li>
                <Link to="/planos" className="text-gray-600 hover:text-primary">
                  Planos
                </Link>
              </li>
              <li>
                <Link to="/cobertura" className="text-gray-600 hover:text-primary">
                  Cobertura
                </Link>
              </li>
              <li>
                <Link to="/suporte" className="text-gray-600 hover:text-primary">
                  Suporte
                </Link>
              </li>
              <li>
                <Link to="/Contato" className="text-gray-600 hover:text-primary">
                  Contato
                </Link>
              </li>
            </ul>
          </div>          
        </div>
        
        <div className="border-t border-gray-200 mt-8 pt-8 text-center text-gray-600">
          <p className="mb-2">© {new Date().getFullYear()} Maxcon Internet. Todos os direitos reservados.</p>
          <p className="flex items-center justify-center gap-2">
            Desenvolvido por Taciano Pinheiro
            <a 
              href="https://www.linkedin.com/in/taciano-pinheiro-de-almeida-alcantara/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:text-primary/80"
              aria-label="LinkedIn"
            >
              <Linkedin size={16} />
            </a>
            {" "}• {" "}
            <a 
              href="https://github.com/tacianopinheiro"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:text-primary/80"
              aria-label="GitHub"
            >
              <Github size={16} />
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
