
import { ArrowRight, Zap, MapPin, HeadphonesIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";

const HeroSection = () => {
  const headingRef = useRef<HTMLHeadingElement>(null);
  
  useEffect(() => {
    // Mark the LCP element for measurement
    if (headingRef.current) {
      // Add this to help browsers identify the LCP element
      headingRef.current.setAttribute('fetchpriority', 'high');
      
      // Log when the heading is rendered
      console.log('LCP element rendered');
    }
  }, []);

  return (
    <section className="pt-24 pb-12 sm:pt-32 sm:pb-16 relative overflow-hidden">
      <div className="absolute inset-0 bg-primary-light/10" />
      <div className="container mx-auto px-4 relative">
        <div className="max-w-3xl mx-auto text-center">
          <span className="inline-block px-4 py-1 mb-6 text-sm font-medium text-primary bg-primary-light/20 rounded-full">
            Internet de Alta Velocidade
          </span>
          <h1 
            ref={headingRef}
            className="text-4xl sm:text-5xl md:text-6xl font-bold text-secondary mb-6"
            style={{ 
              textRendering: 'optimizeLegibility',
              WebkitFontSmoothing: 'antialiased'
            }}
            id="main-heading"
          >
            Conexão rápida e estável para sua casa ou empresa
          </h1>
          <p className="text-lg text-secondary-light mb-8">
            Navegue sem limites com a Maxcon Internet. Velocidade, qualidade e suporte
            24/7 para você aproveitar o melhor da internet.
          </p>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <Link
              to="/planos"
              className="w-full sm:w-auto px-8 py-3 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors flex items-center justify-center gap-2 group font-medium"
              style={{ backgroundColor: "#A04000" }}
            >
              Ver Planos
              <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
            </Link>
            <Link
              to="/cobertura"
              className="w-full sm:w-auto px-8 py-3 bg-white text-secondary border-2 border-primary rounded-lg hover:bg-primary-light/20 transition-colors font-medium"
              style={{ color: "#333333", borderColor: "#A04000" }}
            >
              Verificar Cobertura
            </Link>
          </div>
        </div>
        
        {/* Caixas informativas */}
        <div 
          className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-16"
          style={{ contentVisibility: 'auto', containIntrinsicSize: '0 500px' }}
        >
          {/* Caixa 1: Alta Velocidade */}
          <div className="bg-white rounded-lg shadow-lg p-6 border border-gray-100 hover:shadow-xl transition-shadow">
            <div className="bg-primary-light/20 rounded-full w-12 h-12 flex items-center justify-center mb-4">
              <Zap className="text-primary w-6 h-6" />
            </div>
            <h3 className="text-xl font-bold text-secondary mb-2">Alta Velocidade</h3>
            <p className="text-secondary-light">
              Conexão ultra-rápida para streaming, jogos e trabalho remoto.
            </p>
          </div>
          
          {/* Caixa 2: Ampla Cobertura */}
          <div className="bg-white rounded-lg shadow-lg p-6 border border-gray-100 hover:shadow-xl transition-shadow">
            <div className="bg-primary-light/20 rounded-full w-12 h-12 flex items-center justify-center mb-4">
              <MapPin className="text-primary w-6 h-6" />
            </div>
            <h3 className="text-xl font-bold text-secondary mb-2">Ampla Cobertura</h3>
            <p className="text-secondary-light">
              Presente em Farias Brito - CE e Altaneira - CE com sinal de qualidade.
            </p>
          </div>
          
          {/* Caixa 3: Suporte 24/7 */}
          <div className="bg-white rounded-lg shadow-lg p-6 border border-gray-100 hover:shadow-xl transition-shadow">
            <div className="bg-primary-light/20 rounded-full w-12 h-12 flex items-center justify-center mb-4">
              <HeadphonesIcon className="text-primary w-6 h-6" />
            </div>
            <h3 className="text-xl font-bold text-secondary mb-2">Suporte 24/7</h3>
            <p className="text-secondary-light">
              Assistência técnica especializada disponível a qualquer momento.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
