
import { Suspense, lazy, useEffect } from "react";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

// Import HeroSection directly to avoid lazy loading which can delay LCP
import HeroSection from "@/components/HeroSection";

const Loading = () => (
  <div className="flex items-center justify-center min-h-[60vh]">
    <div className="animate-pulse text-primary">Carregando...</div>
  </div>
);

const Index = () => {
  // Mark the body as having fonts loaded to prevent layout shifts
  useEffect(() => {
    // Add a class to the body when the document is loaded with fonts
    if (document.fonts) {
      document.fonts.ready.then(() => {
        document.body.classList.add('fonts-loaded');
      });
    }
    
    // Create a footer placeholder if it doesn't exist
    if (!document.getElementById('footerPlaceholder')) {
      const placeholder = document.createElement('div');
      placeholder.id = 'footerPlaceholder';
      placeholder.className = 'footer-placeholder';
      placeholder.setAttribute('aria-hidden', 'true');
      placeholder.style.contain = 'layout size style';
      document.body.appendChild(placeholder);
    }
    
    // Set the page description for better SEO
    document.title = "Maxcon Internet - Internet de Alta Velocidade";
    
    // For performance monitoring
    if (window.performance) {
      const observer = new PerformanceObserver((list) => {
        list.getEntries().forEach((entry) => {
          if (entry.entryType === 'largest-contentful-paint') {
            console.log('LCP:', entry.startTime);
            // Check if entry has element property (PerformancePaintTiming)
            const lcpEntry = entry as any;
            if (lcpEntry.element) {
              console.log('LCP element:', lcpEntry.element);
            }
          }
        });
      });
      observer.observe({ type: 'largest-contentful-paint', buffered: true });
    }
    
    // Clean up function for unmounting
    return () => {
      document.body.classList.remove('fonts-loaded');
      const placeholder = document.getElementById('footerPlaceholder');
      if (placeholder) {
        placeholder.remove();
      }
    };
  }, []);

  return (
    <>
      <header>
        <Navbar />
      </header>
      
      <main className="flex-grow">
        <HeroSection />
      </main>

      <Footer />
    </>
  );
};

export default Index;
