
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { Clock, Headset, MessageSquare } from "lucide-react";
import { getWhatsAppLink } from "@/lib/whatsapp";

const Suporte = () => {
  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Navbar />
      <main className="flex-grow container mx-auto px-4 pt-24 pb-12">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-secondary mb-6">Suporte Técnico</h1>
          <div className="grid gap-8">
            <section className="grid md:grid-cols-2 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
                <div className="flex items-start space-x-3 mb-6">
                  <MessageSquare className="w-6 h-6 text-primary" />
                  <div>
                    <h2 className="text-2xl font-bold text-primary mb-4">Canais de Atendimento</h2>
                    <p className="text-gray-600 mb-4">
                      WhatsApp disponível em horário comercial para suporte técnico especializado
                    </p>
                    <div className="text-gray-600 mb-4">
                      <p className="font-semibold">Horário de Funcionamento:</p>
                      <p>Segunda a Sexta: 8h às 18h</p>
                      <p>Sábado: 8h às 12h</p>
                    </div>
                    <a
                      href={getWhatsAppLink("Olá! Preciso de suporte técnico.")}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-block px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors"
                    >
                      Contatar via WhatsApp
                    </a>
                  </div>
                </div>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
                <div className="flex items-start space-x-3 mb-6">
                  <Headset className="w-6 h-6 text-primary" />
                  <div>
                    <h2 className="text-2xl font-bold text-primary mb-4">Central do Assinante</h2>
                    <p className="text-gray-600 mb-4">
                      Serviços disponíveis 24h/dia na Central do Assinante:
                    </p>
                    <ul className="space-y-2 text-gray-600">
                      <li>• Abertura de chamados</li>
                      <li>• Segunda-via de títulos para pagamento</li>
                      <li>• Registrar promessa de pagamento</li>
                      <li>• Ver informações sobre plano e contrato</li>
                      <li>• Declaração de quitação</li>
                      <li>• Relatórios de uso</li>
                    </ul>
                    <a
                      href="https://sgp.maxcon.com.br/accounts/central/login"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mt-6 inline-block px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors"
                    >
                      Acessar Central do Assinante
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Suporte;
