
import { lazy, Suspense } from "react";
import { Mail, Phone, Clock, MapPin, Instagram } from "lucide-react";
import { getWhatsAppLink } from "@/lib/whatsapp";

// Lazy load components
const Navbar = lazy(() => import("@/components/Navbar"));
const Footer = lazy(() => import("@/components/Footer"));

// Componente de loading
const LoadingComponent = () => (
  <div className="flex items-center justify-center min-h-[60vh]">
    <div className="animate-pulse text-primary">Carregando...</div>
  </div>
);

const Contato = () => {
  // Funções para formatar os links
  const formatPhoneLink = (phone: string) => {
    return `tel:${phone.replace(/\D/g, '')}`;
  };

  const formatEmailLink = (email: string) => {
    return `mailto:${email}`;
  };

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Suspense fallback={<LoadingComponent />}>
        <Navbar />
      </Suspense>
      
      <main className="flex-grow container mx-auto px-4 pt-24 pb-12">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-secondary mb-6">Entre em Contato</h1>
          <div className="grid gap-8">
            <section className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
              <div className="grid md:grid-cols-2 gap-8">
                <div>
                  <h2 className="text-2xl font-bold text-primary mb-6">Informações de Contato</h2>
                  
                  <div className="space-y-6">
                    <div className="flex items-start space-x-3">
                      <Mail className="w-5 h-5 text-primary mt-1" />
                      <div>
                        <h3 className="font-semibold mb-1">Email</h3>
                        <a 
                          href={formatEmailLink("contato@maxcon.com.br")}
                          className="text-primary hover:underline"
                        >
                          contato@maxcon.com.br
                        </a>
                      </div>
                    </div>

                    <div className="flex items-start space-x-3">
                      <Phone className="w-5 h-5 text-primary mt-1" />
                      <div>
                        <h3 className="font-semibold mb-1">WhatsApp e Telefones</h3>
                        <a 
                          href={getWhatsAppLink()}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary hover:underline block mb-1"
                        >
                          +55 88 9625-9600 (WhatsApp)
                        </a>
                        <a 
                          href={formatPhoneLink("88 3544 1338")}
                          className="text-primary hover:underline block mb-1"
                        >
                          +55 88 3544 1338
                        </a>
                        <a 
                          href={formatPhoneLink("0800 085 3047")}
                          className="text-primary hover:underline block"
                        >
                          0800 085 3047
                        </a>
                      </div>
                    </div>

                    <div className="flex items-start space-x-3">
                      <MapPin className="w-5 h-5 text-primary mt-1" />
                      <div>
                        <h3 className="font-semibold mb-1">Endereço</h3>
                        <p className="text-gray-600">Rua Manoel Pinheiro de Almeida, 145</p>
                        <p className="text-gray-600">Centro - Farias Brito - CE</p>
                        <p className="text-gray-600">CEP: 63185-000</p>
                      </div>
                    </div>

                    <div className="flex items-start space-x-3">
                      <Instagram className="w-5 h-5 text-primary mt-1" />
                      <div>
                        <h3 className="font-semibold mb-1">Redes Sociais</h3>
                        <a 
                          href="https://www.instagram.com/maxcon_internet"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary hover:underline"
                        >
                          @maxcon_internet
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <div className="flex items-start space-x-3 mb-6">
                      <Clock className="w-5 h-5 text-primary mt-1" />
                      <div>
                        <h3 className="font-semibold mb-2">Horário de Atendimento</h3>
                        <p className="text-gray-600">Segunda a Sexta: 8h às 18h</p>
                        <p className="text-gray-600">Sábado: 8h às 12h</p>
                      </div>
                    </div>
                    <p className="text-gray-600">
                      Suporte técnico disponível em horário comercial através do nosso WhatsApp
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>

      <Suspense fallback={<LoadingComponent />}>
        <Footer />
      </Suspense>
    </div>
  );
};

export default Contato;
