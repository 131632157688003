
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { getAllLocations } from "@/lib/locations";

type LocationSelectorProps = {
  value: string;
  onValueChange: (value: string) => void;
};

const LocationSelector = ({ value, onValueChange }: LocationSelectorProps) => {
  const locations = getAllLocations();
  
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger className="w-[200px]">
        <SelectValue placeholder="Selecione sua cidade" />
      </SelectTrigger>
      <SelectContent>
        {locations.map((location) => (
          <SelectItem key={location.id} value={location.id}>
            {location.name} - {location.state}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default LocationSelector;
