import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

const Termos = () => {
  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Navbar />
      <main className="container mx-auto px-4 pt-24 pb-12 flex-grow">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-secondary mb-6">Termos e Condições de Uso</h1>
          <div className="prose max-w-none">
            <p className="text-lg text-gray-600 mb-6">
              Última atualização: 22 de fevereiro de 2025
            </p>

            <p className="text-lg text-gray-600 mb-6">
              Bem-vindo(a) aos serviços da Maxcon Internet. Estes Termos e Condições regulam o uso dos serviços prestados e estabelecem os direitos e responsabilidades entre a Maxcon Internet e seus clientes. Ao utilizar nossos serviços, você concorda integralmente com os termos aqui descritos.
            </p>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-primary mb-4">1. Aceitação dos Termos</h2>
              <p className="text-gray-600">
                Ao acessar e utilizar os serviços da Maxcon Internet, você declara ter lido, compreendido e aceitado todos os termos e condições estabelecidos neste documento. Caso não concorde com algum dos termos, recomendamos que não utilize nossos serviços.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-primary mb-4">2. Descrição dos Serviços</h2>
              <p className="text-gray-600">
                A Maxcon Internet fornece serviços de conexão à internet banda larga, podendo incluir pacotes adicionais e assistência técnica. A disponibilidade dos serviços está sujeita à viabilidade técnica e geográfica.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-primary mb-4">3. Responsabilidades do Cliente</h2>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Fornecer informações precisas, atualizadas e verdadeiras no momento da contratação;</li>
                <li>Utilizar os serviços de forma legal, respeitando a legislação vigente e os bons costumes;</li>
                <li>Manter seus dados de acesso, como login e senha, em sigilo, sendo responsável por quaisquer atividades realizadas por meio de sua conta;</li>
                <li>Não utilizar os serviços para fins ilícitos, incluindo disseminação de malware, ataques cibernéticos ou violação de direitos autorais.</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-primary mb-4">4. Planos, Pagamentos e Cobrança</h2>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Os valores e formas de pagamento serão definidos no momento da contratação do plano;</li>
                <li>As cobranças são efetuadas conforme os termos acordados no contrato de prestação de serviços;</li>
                <li>O atraso no pagamento pode resultar na suspensão ou cancelamento do serviço, conforme estipulado na política de inadimplência da empresa.</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-primary mb-4">5. Política de Cancelamento</h2>
              <p className="text-gray-600">
                O cliente pode solicitar o cancelamento do serviço a qualquer momento, de acordo com as condições estabelecidas no contrato. Caso haja fidelização, poderá haver cobrança de multa proporcional ao período restante do contrato.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-primary mb-4">6. Limitação de Responsabilidade</h2>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Falhas decorrentes de fatores externos, como eventos de força maior, interrupção de energia elétrica, problemas em redes de terceiros;</li>
                <li>Perdas ou danos causados por uso indevido do serviço;</li>
                <li>Danos resultantes de ataques cibernéticos ou invasão à rede do cliente.</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-primary mb-4">7. Privacidade e Proteção de Dados</h2>
              <p className="text-gray-600">
                A Maxcon Internet segue rigorosos padrões de segurança e privacidade, conforme a Lei Geral de Proteção de Dados (LGPD). Os dados do cliente serão utilizados exclusivamente para a prestação dos serviços e não serão compartilhados com terceiros sem autorização.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-primary mb-4">8. Disposições Gerais</h2>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>A Maxcon Internet se reserva o direito de alterar estes Termos e Condições a qualquer momento, mediante aviso prévio;</li>
                <li>O uso contínuo do serviço após eventuais alterações implica na aceitação dos novos termos;</li>
                <li>Em caso de dúvidas, o cliente pode entrar em contato com o suporte através dos canais oficiais da empresa.</li>
              </ul>
            </section>

            <p className="text-gray-600">
              Caso tenha alguma dúvida ou precise de mais informações, entre em contato pelo nosso site www.maxcon.com.br ou pelo nosso suporte técnico.
            </p>
            <p className="text-gray-600">
              Maxcon Internet - Conectando você ao mundo!
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Termos;
