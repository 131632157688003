
import { useState } from "react";
import { Menu, X } from "lucide-react";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    { label: "Home", href: "/", internal: true },
    { label: "Sobre", href: "/sobre", internal: true },
    { label: "Planos", href: "/planos", internal: true },
    { label: "Cobertura", href: "/cobertura", internal: true },
    { label: "Suporte", href: "/suporte", internal: true },
    { label: "Contato", href: "/contato", internal: true },
  ];

  const renderMenuItem = (item: { label: string; href: string; internal: boolean }) => {
    if (item.internal) {
      return (
        <Link
          key={item.label}
          to={item.href}
          className="text-gray-600 hover:text-primary transition-colors font-medium text-lg"
          onClick={() => setIsOpen(false)}
        >
          {item.label}
        </Link>
      );
    }
    return (
      <a
        key={item.label}
        href={item.href}
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-600 hover:text-primary transition-colors font-medium text-lg"
        onClick={() => setIsOpen(false)}
      >
        {item.label}
      </a>
    );
  };

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-lg border-b border-gray-200">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <Link to="/" className="flex items-center space-x-2">
            <img 
              src="/lovable-uploads/874e8452-8b9f-4210-a50e-075050a4afac.png" 
              alt="Maxcon Internet Logo" 
              className="h-10" 
              width="120"
              height="32"
              loading="eager"
              style={{ objectFit: "contain" }}
            />
          </Link>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-8">
            {menuItems.map(renderMenuItem)}
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden p-2"
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Toggle menu"
          >
            {isOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile Menu - Completely solid background */}
      <div
        className={cn(
          "fixed inset-0 top-16 bg-white transform transition-transform duration-200 ease-in-out md:hidden shadow-lg z-50",
          isOpen ? "translate-x-0" : "translate-x-full"
        )}
        style={{ 
          backgroundColor: "#FFFFFF",
          opacity: 1
        }}
      >
        <div className="flex flex-col space-y-6 p-8 bg-white">
          {menuItems.map((item) => (
            <div key={item.label} className="border-b border-gray-100 pb-6 bg-white">
              {renderMenuItem(item)}
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
